export enum WARNING_PANELS {
  CONTRIBUTORS = 'dontShowContributorsWarning',
  DISABLE_OPT_IN = 'disableOptInCheckboxWarning'
}

interface warningPanelDefinition {
  title: string
  panelTitle: string
  helpId?: string
  hideUserPreferences?: boolean
  bullets: string[]
  ok: string
  cancel: string
  height: number
}

export const warningTypeToDefinition: { [key in WARNING_PANELS]: warningPanelDefinition } = {
  [WARNING_PANELS.CONTRIBUTORS]: {
    title: 'siteContributorWarning.title',
    panelTitle: 'siteContributorWarning.panelTitle',
    helpId: '2e748e6c-e672-484a-8ec2-7a206f1a753d',
    bullets: ['siteContributorWarning.content.bullet1', 'siteContributorWarning.content.bullet2'],
    ok: 'siteContributorWarning.approveButtonText',
    cancel: 'siteContributorWarning.cancelButtonText',
    height: 306,
  },
  [WARNING_PANELS.DISABLE_OPT_IN]: {
    title: 'subscriberDoubleOptInWarning.title',
    panelTitle: 'subscriberDoubleOptInWarning.popupTitle',
    hideUserPreferences: true,
    bullets: ['subscriberDoubleOptInWarning.subtitle'],
    ok: 'subscriberDoubleOptInWarning.ok',
    cancel: 'subscriberDoubleOptInWarning.cancel',
    height: 260
  },
}
